import React from 'react'
import axios from 'axios'
import { Editors } from "react-data-grid-addons"
import { Link } from 'react-router-dom'

import BackButtons from '../shared/BackButtons'
import FunTextField from '../../forms/FunTextField'
import DataGrid from '../../shared/DataGrid'
import FlashNotification from '../../shared/FlashNotification'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import extractDataErrors from '../../support/extractDataErrors'

import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Close from '@material-ui/icons/Close'
import qs from 'querystringify'

import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'
import { Box } from '@material-ui/core'

const { AutoComplete } = Editors

const transferKinds = [
  { id: "regular", name: "Regular" },
  { id: "sales", name: "Sales" },
  { id: "write_off", name: "Write Off" },
  { id: "manual_counting", name: "Manual Counting" },
  { id: "other", name: "Other" },
  { id: "win_lose", name: "Win/lose" }
]

class StockTransferForm extends React.Component {
  state = {
    rows: [{}],
    products: [],
    stock_transfer: {},
    warehouses: [],
    toWarehouse: '',
    fromWarehouse: '',
    kind: 'regular',
    description: '',
    errors: {},
    loading: false,
    selectDisabled: false,
    showNotEnoughStock: false,
    depos: [],
    fromWarehouses: [],
    selectedDepo: window.depoId,
    isSubmitting: false
  }

  componentDidMount() {
    const { stockTransferId } = this.props.match.params
    this.startLoading()
    this.fetchWarehouses()
    this.fetchDepos()
  }

  fetchProducts() {
    axios.get(Routes.inventory_products_path(window.tenant, window.depoId, { statuses: ["active"], warehouse_id: this.state.fromWarehouse, format: 'json'} )).then(res => {
      this.setState({ products: res.data, rows: [{}] })
      this.stopLoading()
    }).catch(err => {
      this.setState({ message: `${err}` })
      this.stopLoading()
    }).then(() => {
      this.insertSelectedProducts()
    })
  }

  fetchDepos() {
    this.startLoading()
    axios.get(Routes.admin_depos_path(window.tenant, { format: 'json' })).then(res => {
      const dataWithName = res.data.map(d => {
        d.name = d.title
        return d
      })
      this.setState({ depos: dataWithName })
    }).catch(err => {
      this.setState({ message: `${err}` })
      this.stopLoading()
    })
  }

  fetchFromWarehouses(depo) {
    axios.get(Routes.inventory_warehouses_path(window.tenant, depo, { format: 'json' })).then(res => {
      this.setState({ fromWarehouses: res.data })
    }).catch(err => {
      this.setState({ message: `${err}` })
      this.stopLoading()
    })
  }

  fetchWarehouses() {
    axios.get(Routes.inventory_warehouses_path(window.tenant, window.depoId, { format: 'json' })).then(res => {
      const depoWarehouse = res.data.filter(r => r.owner_type === 'Depo' && r.primary)
      const carWarehouse = res.data.filter(r => r.id == this.warehouseId() && r.owner_type == 'Car')
      if (carWarehouse.length > 0) {
        this.setState({
          warehouses: res.data,
          fromWarehouse: depoWarehouse[0] && depoWarehouse[0].id,
          toWarehouse: carWarehouse[0].id
        }, this.setSelectValues.bind(this))
      } else {
        this.setState({
          warehouses: res.data,
          toWarehouse: depoWarehouse[0] && depoWarehouse[0].id
        }, this.setSelectValues.bind(this))
      }
    })
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  insertSelectedProducts() {
    if (!this.props.location.state) return
    const { selectedProducts } = this.props.location.state
    if (!selectedProducts.length > 0) return
    this.setState({
      rows: selectedProducts.filter(p => p.checked && p.filledAmount).map(product => {
        return {
          product_id: product.id,
          sku: product.sku,
          description: product.description,
          package_amount: product.filledPackages || 0,
          amount: product.filledAmount || 0,
          remaining_balance: product.remaining_balance
        }
      }).concat({})
    })
  }

  returnGridColumns() {
    return [
      { key: "sku", name: "Sku", editable: true, editor: this.renderAutoCompleteEditor('sku') },
      { key: "description", name: "Description", editable: true, editor: this.renderAutoCompleteEditor('description') },
      { key: "package_amount", name: "Packages amount", editable: true },
      { key: "amount", name: "Transfer amount", editable: true },
      { key: "remaining_balance", name: "Remaining balance", editable: true, cellClass: "remaining_balance" },
      { key: "action", name: "Actions", editable: false }
    ]
  }

  warehouseId() {
    return this.props.match.params.warehouseId
  }

  avaliableProducts(type) {
    const { products } = this.state
    return products.map(p => ({ id: p.id, title: type == 'sku' ? p.sku : p.description }) )
  }

  setSelectValues() {
    const queryParams = qs.parse(this.props.location.search)
    if (queryParams.kind && queryParams.kind === 'win_lose') {
      this.setState({ kind: queryParams.kind, selectDisabled: true, fromWarehouse: '' })
    }
    this.fetchProducts()
  }

  onGridRowsUpdated({ fromRow, toRow, updated }) {
    this.setState(state => {
      const rows = state.rows.slice()
      const selectedProduct = this.state.products.filter(product => updated[Object.keys(updated)[0]] === product[Object.keys(updated)[0]])
      updated = selectedProduct.length > 0 ? selectedProduct[0] : updated

      for (let i = fromRow; i <= toRow; i++) {
        if (rows[i].product_id) {
          const product = this.state.products.filter(p => p.id === rows[i].product_id)

          const updatedKey = Object.keys(updated)[0]
          switch(updatedKey) {
            case "package_amount":
              updated.amount = updated.package_amount * product[0].packaging_size
              break
            default:
              if (selectedProduct.length > 0) {
                updated.amount = 0
                updated.package_amount = 0
              }
          }
          if (this.state.fromWarehouse) {
            rows[i].remaining_balance = product[0].remaining_balance - updated.amount
            updated.remaining_balance = product[0].remaining_balance - updated.amount
          } else {
            rows[i].remaining_balance = 0
            updated.remaining_balance = 0
          }
        }

        if (selectedProduct.length > 0) {
          rows[i] = { ...rows[i], ...updated, product_id: selectedProduct[0].id }
        } else {
          rows[i] = { ...rows[i], ...updated }
        }
      }
      return { rows }
    }, this.highlightNegatives.bind(this))
  }

  highlightNegatives() {
    if (!!this.state.fromWarehouse) {
      Array.from(document.getElementsByClassName('remaining_balance')).forEach(cell => {
        if (cell.attributes.value && cell.attributes.value.value < 0) {
          cell.classList.add("negative")
          !this.state.showNotEnoughStock && this.setState({ showNotEnoughStock: true })
        } else {
          cell.classList.remove("negative")
        }
      })
    } else {
      Array.from(document.getElementsByClassName('remaining_balance')).forEach(cell => {
        cell.classList.remove("negative")
      })
    }
  }

  handleChange(field, e) {
    if (field === 'kind' && e.target.value === 'win_lose') {
      const rows = this.state.rows.map(row => { row.remaining_balance = 0; return row }).filter(row => row.sku).concat({})
      this.setState({ rows: [{}] }, () => {
        this.setState({
          [field]: e.target.value,
          fromWarehouse: '',
          rows: rows
        }, this.highlightNegatives)
      })
    } else if (field === 'fromWarehouse') {
      if (this.state.kind === 'win_lose') {
        this.setState({ kind: '' })
      }
      this.setState({ [field]: e.target.value }, this.fetchProducts.bind(this))
    } else if(field === 'kind' && e.target.value === 'write_off' && isIsbilen) {
      this.setState({
        [field]: e.target.value,
        toWarehouse: '',
      })
    } else if (field === 'toWarehouse') {
      if (this.state.kind === 'write_off') {
        this.setState({ kind: '' })
      }
      this.setState({ [field]: e.target.value }, this.fetchProducts.bind(this))
    } else {
      this.setState({ [field]: e.target.value })
    }
  }

  url() {
    return Routes.inventory_warehouse_stock_transfers_path(window.tenant, window.depoId, this.warehouseId(), { format: 'json' })
  }

  data() {
    return {
      stock_transfer: {
        stock_transfer_line_items_attributes: this.validRows(),
        to_warehouse_id: this.state.toWarehouse,
        from_warehouse_id: this.state.fromWarehouse,
        kind: this.state.kind,
        description: this.state.description,
        main_storage_message: this.state.main_storage_message
      },
      format: 'json',
    }
  }

  validRows() {
    return this.state.rows.filter(row => row.sku && row.amount != 0).map(r => { return { product_id: r.product_id, amount: r.amount } })
  }

  handleSubmitSuccess(response) {
    this.props.history.push(Routes.show_inventory_warehouse_stock_transfers_path(window.tenant, window.depoId, this.warehouseId(), response.data.id))
  }

  clearErrors() {
    this.setState({ full_errors: null, errors: {} })
  }

  handleSubmitError(error) {
    this.setState({ ...extractDataErrors(error), loading: false })
  }

  updateIsSubmiting() {
    this.setState({ isSubmitting: false })
  }

  submit() {
    if (this.state.isSubmitting) return;
    this.setState({ isSubmitting: true });

    this.startLoading()
    axios({
      method: 'post',
      url: this.url(),
      data: this.data()
    }).then(
      this.handleSubmitSuccess.bind(this)
    ).catch(
      this.handleSubmitError.bind(this)
    )

    setTimeout(this.updateIsSubmiting.bind(this), 2000)
  }

  removeCurrentRow(deletable_row) {
    if (this.state.rows.length == 1) return
    const { rows } = this.state
    this.setState({ rows: rows.filter(row => row !== deletable_row) })
  }

  getCellActions(column, row) {
    const cellActions = [
      {
        icon: <Close style={{ marginTop: 5 }} />,
        callback: this.removeCurrentRow.bind(this, row),
      }
    ]

    return column.key == 'action' ? cellActions : null
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading}/>
  }

  renderAutoCompleteEditor(type) {
    if (this.state.products.length > 0) {
      return <AutoComplete options={this.avaliableProducts(type)} />
    }
  }

  handleDepoChange(e) {
    this.setState({ selectedDepo: e.target.value }, () => this.fetchFromWarehouses(this.state.selectedDepo))
  }

  renderDeposSelect() {
    const { depos, selectDisabled, selectedDepo } = this.state
    return (
      <FormControl>
        <InputLabel htmlFor="warehouse-select">Depo</InputLabel>
        <Select
          className={`warehouse-select depo-select`}
          id="depo-select"
          disabled={selectDisabled}
          value={selectedDepo}
          onChange={this.handleDepoChange.bind(this)}>
          {this.renderSelectOptions(depos)}
        </Select>
      </FormControl>
    )
  }

  renderWarehouseSelect(field) {
    const { warehouses, selectDisabled } = this.state
    return (
      <FormControl>
        <InputLabel htmlFor="warehouse-select">{startCase(field)}</InputLabel>
        <Select
          className={`warehouse-select ${field}`}
          id={field}
          disabled={selectDisabled}
          value={this.state[field] || ''}
          onChange={this.handleChange.bind(this, field)}>
          {field == 'kind' ? this.renderSelectOptions(transferKinds, field) : this.renderSelectOptions(warehouses, field)}
        </Select>
      </FormControl>
    )
  }

  renderSelectOptions(options, field) {
    if (field === 'fromWarehouse' && this.state.fromWarehouses.length > 0) {
      options = this.state.fromWarehouses
    }
    return (
      options.map(option => {
        return (
          <MenuItem key={option.id} value={option.id}>
            <ListItemText primary={option.name} />
          </MenuItem>
        )
      })
    )
  }

  renderDescriptionInputField() {
    return (
      <FormControl>
        <FunTextField style={{ width: 200, marginRight: 10, marginTop: 0, marginBottom: 0 }} field="description" state={this.state} handleChange={this.handleChange.bind(this, 'description')} />
      </FormControl>
    )
  }

  renderMainStorageMessageField() {
    if (!this.state.fromWarehouse) {
      return (
        <FormControl>
          <FunTextField
          style={{ width: 200, marginRight: 10, marginTop: 0, marginBottom: 0 }}
          field="main_storage_message"
          label="Movement order ID"
          state={this.state}
          handleChange={this.handleChange.bind(this, 'main_storage_message')} />
        </FormControl>
      )
    }
  }

  renderMainTitle() {
    return (
      <Typography variant="h5" style={{ marginLeft: 10 }}>New Stock Transfer</Typography>
    )
  }

  renderFlashNotification() {
    const { full_errors } = this.state
    const message = !isEmpty(full_errors) ? `${full_errors.join(", ")}` : null
    return <FlashNotification message={message} clearErrors={this.clearErrors.bind(this)} />
  }

  renderSubmitButton() {
    return (
      <Button variant="contained" color="primary" onClick={this.submit.bind(this)} disabled={this.state.isSubmitting}>Create Transfer</Button>
    )
  }

  renderBackButtons() {
    return (
      <BackButtons warehouseId={this.warehouseId()} parent="transfers"/>
    )
  }

  renderRectDataGrid() {
    return <DataGrid
      onGridRowsUpdated={this.onGridRowsUpdated.bind(this)}
      getCellActions={this.getCellActions.bind(this)}
      columns={this.returnGridColumns()}
      appendEmptyRow={this.appendEmptyRow.bind(this)}
      rows={this.state.rows} />
  }

  appendEmptyRow(e) {
    if (e.rowIdx == this.state.rows.length - 1 ) {
      this.setState({ rows: [...this.state.rows, { }] })
    }
  }

  renderNewTransferFromDepot() {
    const { fromWarehouse, showNotEnoughStock } = this.state

    if (showNotEnoughStock && fromWarehouse) {
      return (
        <a style={{ marginLeft: 10 }} href={Routes.new_inventory_warehouse_stock_transfers_path(window.tenant, window.depoId, this.state.fromWarehouse)} target="_blank">
          <strong>Not enough stock?</strong>
        </a>
      )
    }
  }

  render() {
    return (
      <div>
        <div className="content-header">
          <div className="content-header-title">
            {this.renderBackButtons()}
            {this.renderMainTitle()}
            {this.renderNewTransferFromDepot()}
          </div>
        </div>
        <Box className="content-subheader">
          {this.renderDeposSelect()}
          {this.renderWarehouseSelect("fromWarehouse")}
          {this.renderWarehouseSelect("toWarehouse")}
          {this.renderWarehouseSelect("kind")}
          {this.renderDescriptionInputField()}
          {this.renderMainStorageMessageField()}
          {this.renderSubmitButton()}
        </Box>
        {this.renderRectDataGrid()}
        {this.renderFlashNotification()}
        {this.renderLoading()}
      </div>
    )
  }
}

export default StockTransferForm
